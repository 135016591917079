var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-provider',{attrs:{"rules":_vm.validations.username,"name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.user.username,"label":_vm.$t('attributes.users.username'),"name":"username","error-messages":errors,"type":"text"},on:{"input":function($event){return _vm.usernameUpdatedTo($event)}}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.password,"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.user.password,"label":_vm.$t('attributes.users.password'),"error-messages":errors,"type":"password"},on:{"input":function($event){return _vm.passwordUpdatedTo($event)}}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.passwordConfirmation,"name":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.user.passwordConfirmation,"label":_vm.$t('attributes.users.passwordConfirmation'),"type":"password","error-messages":errors},on:{"input":function($event){return _vm.passwordConfirmationUpdatedTo($event)}}})]}}])}),(_vm.validations.zones)?_c('validation-provider',{attrs:{"rules":_vm.validations.zones,"name":"zones","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"value":_vm.user.zoneIds,"items":_vm.zones,"error-messages":errors,"item-text":"label","item-value":"value","label":_vm.$t('attributes.users.zones'),"multiple":""},on:{"input":_vm.zonesChanged}})]}}],null,false,1087566436)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }