var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-provider',{attrs:{"vid":"bettingPoolSchedules.openingTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hidden":""}})]}}])}),_vm._l((_vm.weekdays),function(weekday){return _c('v-row',{key:weekday.id,staticClass:"mt-3"},[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.scheduleForDay(weekday).openingTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.scheduleForDayDisplayValue(weekday).openingTime,"label":_vm.$t('enumerations.weekday.' + weekday.name.toLowerCase()) + ' (' +_vm.$t('views.groups.create_or_update.start') +')',"prepend-icon":"mdi-clock-time-four-outline","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menus[weekday.id].openingTime),callback:function ($$v) {_vm.$set(_vm.menus[weekday.id], "openingTime", $$v)},expression:"menus[weekday.id].openingTime"}},[(_vm.menus[weekday.id].openingTime)?_c('v-time-picker',{attrs:{"value":_vm.scheduleForDay(weekday).openingTime,"format":"ampm","max":_vm.scheduleForDay(weekday).closingTime,"ampm-in-title":"","full-width":""},on:{"input":function($event){return _vm.scheduleUpdatedOpeningTime($event, weekday)}}}):_vm._e()],1)],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.scheduleForDay(weekday).closingTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.scheduleForDayDisplayValue(weekday).closingTime,"label":_vm.$t('enumerations.weekday.' + weekday.name.toLowerCase()) + ' (' +_vm.$t('views.groups.create_or_update.finish') +')',"prepend-icon":"mdi-clock-time-four-outline","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menus[weekday.id].closingTime),callback:function ($$v) {_vm.$set(_vm.menus[weekday.id], "closingTime", $$v)},expression:"menus[weekday.id].closingTime"}},[(_vm.menus[weekday.id].closingTime)?_c('v-time-picker',{attrs:{"value":_vm.scheduleForDay(weekday).closingTime,"min":_vm.scheduleForDay(weekday).openingTime,"format":"ampm","ampm-in-title":"","full-width":""},on:{"input":function($event){return _vm.scheduleUpdatedClosingTime($event, weekday)}}}):_vm._e()],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }