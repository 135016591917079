<template>
  <v-row
    align="center"
    justify="center">
    <v-col md="10">
      <v-card>
        <v-card-title>
          {{
            $t("crudActions." + (id !== null ? "updateModel" : "createModel"), { model: $t("models.groups.single") })
          }}
        </v-card-title>
        <v-card-text>
          <v-tabs
            v-model="tab"
            fixed-tabs>
            <v-tab
              key="general"
              :class="errorClassForTab('general')">
              {{ $t("views.groups.create_or_update.tab_names.general") }}
            </v-tab>
            <v-tab
              v-if="id === null"
              key="user"
              :class="errorClassForTab('user')">
              {{ $t("views.groups.create_or_update.tab_names.initial_user") }}
            </v-tab>
            <v-tab
              key="configuration"
              :class="errorClassForTab('configuration')">
              {{ $t("views.groups.create_or_update.tab_names.configuration") }}
            </v-tab>
            <v-tab
              key="footers"
              :class="errorClassForTab('footers')">
              {{ $t("views.groups.create_or_update.tab_names.footers") }}
            </v-tab>
            <v-tab
              key="cash-outs-and-commissions"
              :class="errorClassForTab('cashOutsAndCommissions')">
              {{ $t("views.groups.create_or_update.tab_names.cash_outs_and_commissions") }}
            </v-tab>
            <v-tab
              key="schedules"
              :class="errorClassForTab('schedules')">
              {{ $t("views.groups.create_or_update.tab_names.schedules") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <validation-observer
              ref="generalValidationObserver"
              slim>
              <v-tab-item
                key="general"
                eager>
                <group-form
                  :group.sync="form.group"
                  :validations="validationRules"
                  :params="params" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              v-if="id === null"
              ref="userValidationObserver"
              slim>
              <v-tab-item
                key="user"
                eager>
                <user-form
                  :user.sync="form.group.user"
                  :validations="validationRules.user" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="configurationValidationObserver"
              slim>
              <v-tab-item
                key="configuration"
                eager>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.cancelTicketTime"
                  name="cancel_ticket_time"
                  slim>
                  <v-text-field
                    v-model="form.group.cancelTicketTime"
                    :label="$t('attributes.groups.cancel_ticket_time')"
                    :error-messages="errors"
                    name="cancelTicketTime"
                    type="text" />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.maximumAmountPerPlay"
                  name="maximumAmountPerPlay"
                  slim>
                  <v-text-field
                    v-model="form.group.maximumAmountPerPlay"
                    :error-messages="errors"
                    name="maximumAmountPerPlay"
                    :label="$t('attributes.groups.maximum_amount_per_play')" />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.timeZone"
                  name="timeZone"
                  slim>
                  <v-autocomplete
                    v-model="form.group.timeZone"
                    :items="params.timeZones"
                    :error-messages="errors"
                    item-text="label"
                    item-value="value"
                    :label="$t('fields.timeZone')" />
                </validation-provider>
                <v-switch
                  v-model="form.group.active"
                  :label="$t('attributes.groups.active')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <v-switch
                  v-model="form.group.winningTicketControl"
                  :label="$t('attributes.groups.winning_ticket_control')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <v-switch
                  v-model="form.group.showNetPurseInBettingPools"
                  :label="$t('attributes.groups.show_net_purse_in_betting_pools')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <v-switch
                  v-model="form.group.jackpotEnabled"
                  :label="$t('attributes.groups.jackpot_enabled')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <validation-provider
                  v-if="form.group.jackpotEnabled"
                  v-slot="{ errors }"
                  :rules="validationRules.jackpotRakePercentage"
                  name="jackpot_rake_percentage"
                  slim>
                  <v-text-field
                    v-model="form.group.jackpotRakePercentage"
                    :label="$t('attributes.groups.jackpot_rake_percentage')"
                    :error-messages="errors"
                    name="jackpotRakePercentage"
                    type="text" />
                </validation-provider>
                <validation-provider
                  v-if="form.group.jackpotEnabled"
                  v-slot="{ errors }"
                  :rules="validationRules.jackpotMinimumWinnableAmount"
                  name="jackpot_minimum_winnable_amount"
                  slim>
                  <v-text-field
                    v-model="form.group.jackpotMinimumWinnableAmount"
                    :label="$t('attributes.groups.jackpot_minimum_winnable_amount')"
                    :error-messages="errors"
                    name="jackpotMinimumWinnableAmount"
                    type="text" />
                </validation-provider>
                <validation-provider
                  v-if="form.group.jackpotEnabled"
                  v-slot="{ errors }"
                  :rules="validationRules.minJackpotBet"
                  name="min_jackpot_bet"
                  slim>
                  <v-text-field
                    v-model="form.group.minJackpotBet"
                    :label="$t('attributes.groups.min_jackpot_bet')"
                    :error-messages="errors"
                    name="minJackpotBet"
                    type="text" />
                </validation-provider>
                <v-switch
                  v-if="form.group.jackpotEnabled"
                  v-model="form.group.displayMinimumJackpot"
                  :label="$t('attributes.groups.display_minimum_jackpot')"
                  color="primary"
                  class="v-input--reverse"
                  hide-details />
                <validation-provider
                  v-if="form.group.jackpotEnabled && form.group.displayMinimumJackpot"
                  v-slot="{ errors }"
                  name="minimum_jackpot_amount"
                  :rules="validationRules.minimumJackpotAmount"
                  slim>
                  <v-text-field
                    v-if="form.group.displayMinimumJackpot"
                    v-model="form.group.minimumJackpotAmount"
                    :label="$t('attributes.groups.minimum_jackpot_amount')"
                    :error-messages="errors" />
                </validation-provider>
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="footersValidationObserver"
              slim>
              <v-tab-item
                key="footers"
                eager>
                <group-or-pool-footer-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="cashOutsAndCommissionsValidationObserver"
              slim>
              <v-tab-item
                key="cas-outs-and-commissions"
                eager>
                <group-or-pool-cash-out-and-commission-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
            <validation-observer
              ref="schedulesValidationObserver"
              slim>
              <v-tab-item key="schedules">
                <group-or-pool-schedule-form
                  :group-or-pool.sync="form.group"
                  :validations="validationRules" />
              </v-tab-item>
            </validation-observer>
          </v-tabs-items>
          <v-card-actions>
            <v-btn
              color="primary"
              @click="$router.push({ name: $routes.group.indexRoute.name })">
              {{ $t("actions.back_to_list") }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              @click="createOrUpdateGroup">
              {{
                $t("crudActions." + (id !== null ? "update" : "create"))
              }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { createGroup, fetchGroup, getParams, updateGroup } from "@/api/group.js";
import GroupForm from "../../components/group/GroupForm.vue";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";
import UserForm from "@/components/user/UserForm.vue";
import GroupOrPoolFooterForm from "@/components/groupOrPool/GroupOrPoolFooterForm.vue";
import GroupOrPoolCashOutAndCommissionForm from "@/components/groupOrPool/GroupOrPoolCashOutAndCommissionForm.vue";
import GroupOrPoolScheduleForm from "@/components/groupOrPool/GroupOrPoolScheduleForm.vue";
import Weekday from "@/imported/Weekday.json";
import { resetData } from "@/utils/ComponentHelper.js";
import HttpStatus from "http-status";

function data() {
  return {
    form: {
      group: {
        name: null,
        domainIds: [],
        maximumAmountPerPlay: null,
        cancelTicketTime: null,
        timeZone: null,
        active: true,
        winningTicketControl: false,
        jackpotEnabled: false,
        jackpotRakePercentage: null,
        jackpotMinimumWinnableAmount: null,
        minJackpotBet: null,
        displayMinimumJackpot: false,
        minimumJackpotAmount: null,
        showNetPurseInBettingPools: false,
        user: {
          username: "",
          password: "",
          passwordConfirmation: "",
        },
        firstFooter: "",
        secondFooter: "",
        thirdFooter: "",
        fourthFooter: "",
        cashOuts: [
          {
            weekday: Weekday.sunday,
            amount: 500,
          },
          {
            weekday: Weekday.monday,
            amount: 500,
          },
          {
            weekday: Weekday.tuesday,
            amount: 500,
          },
          {
            weekday: Weekday.wednesday,
            amount: 500,
          },
          {
            weekday: Weekday.thursday,
            amount: 500,
          },
          {
            weekday: Weekday.friday,
            amount: 500,
          },
          {
            weekday: Weekday.saturday,
            amount: 500,
          },
        ],
        commission: "0",
        schedules: [
          {
            weekday: Weekday.sunday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.monday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.tuesday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.wednesday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.thursday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.friday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
          {
            weekday: Weekday.saturday,
            openingTime: "08:00",
            closingTime: "18:00",
          },
        ],
        rouletteImageAvatar: null,
      },
    },
    tab: null,
    params: {
      timeZones: [],
    },
  };
}

export default {
  name: "GroupCreateOrUpdate",
  components: {
    GroupForm,
    ValidationObserver,
    UserForm,
    GroupOrPoolFooterForm,
    GroupOrPoolCashOutAndCommissionForm,
    GroupOrPoolScheduleForm,
  },
  mixins: [Validatable],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data,
  computed: {
    validationRules() {
      return {
        name: "required",
        user: {
          username: "required",
          password: "required",
          passwordConfirmation: "required_if:password|confirm:password",
        },
        cancelTicketTime: "numeric",
        maximumAmountPerPlay: "numeric",
        timeZone: "required",
        winningTicketControl: "required",
        jackpotRakePercentage: "required",
        jackpotMinimumWinnableAmount: "required",
        minimumJackpotAmount: "required|max_value:" + this.form.group.jackpotMinimumWinnableAmount,
        minJackpotBet: "required",
        firstFooter: "max:30",
        secondFooter: "max:30",
        thirdFooter: "max:30",
        fourthFooter: "max:30",
        cashOuts: "required|numeric",
        commission: "numeric|between:0,50",
        schedules: "required",
      };
    },
  },
  watch: {
    "$route"() {
      this.loadFormData();
    },
  },
  created() {
    this.loadFormData();
  },
  methods: {
    async createOrUpdateGroup() {
      const isValid = await this.validateForm();

      if (isValid) {
        const submitForm = {};
        delete Object.assign(submitForm, this.form.group, { ["cashOutsAttributes"]: this.form.group.cashOuts }).cashOuts;
        delete Object.assign(submitForm, submitForm, { ["schedulesAttributes"]: this.form.group.schedules }).schedules;
        if (this.id === null) {
          delete Object.assign(submitForm, submitForm, { ["usersAttributes"]: [this.form.group.user] }).user;
        }

        (this.id === null
          ?
          createGroup(submitForm)
          :
          updateGroup(this.id, submitForm)).then(() => {
          this.$router.push({
            name: this.$routes.group.indexRoute.name,
          });
        }).
        catch(error => this.handleErrorResponse(error));
      }
    },
    async validateForm() {
      const {
          generalValidationObserver,
          userValidationObserver,
          configurationValidationObserver,
          footersValidationObserver,
          cashOutsAndCommissionsValidationObserver,
          schedulesValidationObserver,
        } = this.$refs,
        userValidation = this.id === null
          ?
          await userValidationObserver.validate()
          :
          true;

      return await generalValidationObserver.validate() &&
        userValidation &&
        await configurationValidationObserver.validate() &&
        await footersValidationObserver.validate() &&
        await cashOutsAndCommissionsValidationObserver.validate() &&
        await schedulesValidationObserver.validate();
    },
    loadFormData() {
      resetData(this.$data, data);
      this.fetchParams();
      if (typeof this.id === "number") {
        this.loadGroup();
      }
    },
    fetchParams() {
      return getParams().
      then(response => {
        const {
          timeZones,
          domains,
        } = response.data;
        this.params.domains = domains;
        this.params.timeZones = Object.keys(timeZones).
        map(key => ({
          label: key,
          value: timeZones[key],
        }));
      });
    },
    loadGroup() {
      return fetchGroup(this.id).
      then(response => {
        const { group } = response.data,
          { rouletteImageAvatarParam, ...rest } = group;
        if (rouletteImageAvatarParam) {
          rest.rouletteImageAvatar = rouletteImageAvatarParam;
        }
        this.form.group = {
          ...rest,
        };
      });
    },
    async handleErrorResponse(error) {
      if (error?.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        const { failures } = error.response.data;

        Object.keys(failures).
        forEach(f => {
          const observer = this.$refs[this.getObserverForField(f)],
            errorObj = {};

          errorObj[f] = failures[f];
          observer.setErrors(errorObj);
        });
        await this.$nextTick();
      }
    },
    getObserverForField(field) {
      switch (field) {
        case "name":
        case "domainIds":
          return "generalValidationObserver";
        case "username":
          return "userValidationObserver";
        case "cancelTicketTime":
        case "maximumAmountPerPlay":
        case "timeZone":
          return "configurationValidationObserver";
        default:
          return "generalValidationObserver";
      }

    },
    errorClassForTab(tabName) {
      if (typeof this.$refs[tabName + "ValidationObserver"] !== "undefined") {
        const validationObserver = this.$refs[tabName + "ValidationObserver"];

        return validationObserver.flags.failed
          ?
          "tab-with-error"
          :
          "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.tab-with-error {
  background: #ffb8b8;
  border-top: 2px solid red;

}

// Reversed input variant
::v-deep .v-input--selection-controls .v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 30px;

  & > * {
    flex: inherit;
  }

  div {
    margin-left: 10px;
  }
}


</style>
