import { render, staticRenderFns } from "./GroupCreateOrUpdate.vue?vue&type=template&id=fb6bd5fe&scoped=true&"
import script from "./GroupCreateOrUpdate.vue?vue&type=script&lang=js&"
export * from "./GroupCreateOrUpdate.vue?vue&type=script&lang=js&"
import style0 from "./GroupCreateOrUpdate.vue?vue&type=style&index=0&id=fb6bd5fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6bd5fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VRow,VSpacer,VSwitch,VTab,VTabItem,VTabs,VTabsItems,VTextField})
