<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.name"
      name="name"
      slim>
      <v-text-field
        :value="group.name"
        :label="$t('attributes.groups.name')"
        :error-messages="errors"
        name="name"
        type="text"
        @input="nameChanged" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      name="domainIds"
      slim>
      <v-autocomplete
        :value="group.domainIds"
        :items="params.domains"
        :error-messages="errors"
        multiple
        item-text="url"
        item-value="id"
        label="Domains"
        @input="$emit('update:group', {...group, domainIds: $event})" />
    </validation-provider>
    <validation-provider
      v-slot="{errors}"
      name="rouletteImageAvatar"
      :rules="validations.image"
      slim>
      <v-file-input
        :value="group.rouletteImageAvatar"
        :error-messages="errors"
        data-vv-validate-on="change"
        accept="image/*"
        label="Roulette Image"
        @change="imageChanged"
        @click:clear="clearImage" />
    </validation-provider>
    <v-btn
      v-if="!imageHasChanged && group.rouletteImageAvatar && group.rouletteImageAvatar.normal.url"
      color="success"
      @click="downloadItem({url: group.rouletteImageAvatar.normal.url, label: 'image'})">
      {{ $t("views.groups.view_file") }}
    </v-btn>
  </v-form>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import axios from "axios";
import { image } from "vee-validate/dist/rules.js";

export default {
  name: "GroupForm",
  components: {
    ValidationProvider,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      imageHasChanged: false
    }
  },
  mounted() {
    extend("image", {
      ...image,
      message: this.$t("validationErrors.image")
    });
  },
  methods: {
    downloadItem({ url, label }) {
      axios.get(`${process.env.VUE_APP_API_URL}${url}`, { responseType: "blob" }).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label + "." + response.data.type.split("/")[1];
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    nameChanged(value) {
      const updatedGroup = {
        ...this.group,
        name: value,
      };

      this.$emit("update:group", updatedGroup);
    },
    imageChanged(value) {
      const updatedGroup = {
        ...this.group,
        rouletteImageAvatar: value,
      };

      this.imageHasChanged = true;
      this.$emit("update:group", updatedGroup);
    },
    clearImage() {
      const updatedGroup = {
        ...this.group,
        rouletteImageAvatar: null,
      };

      this.$emit("update:group", updatedGroup);
    }
  },
};
</script>
