<template>
  <v-form>
    <v-tabs v-model="selectedTab">
      <v-tab key="cashOuts">
        {{ $t("views.groups.create_or_update.tab_names.cash_outs") }}
      </v-tab>
      <v-tab key="commissions">
        {{ $t("views.groups.create_or_update.tab_names.commissions") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item key="cash-outs">
        <v-alert
          color="blue"
          dense
          outlined
          text
          type="success"
          icon="mdi-information-variant"
          dismissible
          class="mt-5">
          {{ $t("views.groups.create_or_update.cash_out_explanation") }}
        </v-alert>
        <v-row>
          <v-col
            v-for="weekday in weekdays"
            :key="weekday.value"
            cols="12"
            sm="6"
            md="3"
            lg="3">
            <validation-provider
              v-slot="{ errors }"
              :rules="validations.cashOuts"
              :name="weekday.name.toLowerCase()"
              slim>
              <v-text-field
                :value="cashOutForDay(weekday)"
                :label="$t('enumerations.weekday.' + weekday.name.toLowerCase())"
                :error-messages="errors"
                type="number"
                @keypress="isNumber"
                @input="cashOutUpdated($event, weekday.id)" />
            </validation-provider>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        key="commissions"
        eager>
        <validation-provider
          v-slot="{ errors }"
          :rules="validations.commission"
          name="commission"
          slim>
          <v-text-field
            :value="groupOrPool.commission"
            :label="$t('fields.commission')"
            :error-messages="errors"
            type="number"
            @keypress="isNumber"
            @input="commissionUpdated($event)" />
        </validation-provider>
      </v-tab-item>
    </v-tabs-items>
  </v-form>
</template>

<script>

import Weekday from "../../imported/Weekday.json";
import { mapJsonEnumToObjects } from "@/services/mappers/jsonEnumToObjectMapper.js";

function data() {
  return {
    selectedTab: 'prizes',
    weekdays: mapJsonEnumToObjects(Weekday),
  };
}

export default {
  name: "GroupOrPoolCashOutAndCommissionForm",
  props: {
    groupOrPool: {
      type: Object,
      required: true,
      description: "The group or pool object that has the fields this form possesses",
    },
    validations: {
      type: Object,
      required: true,
      description: "An object that defined the validations to be used on the fields in this component",
    },
  },
  data,
  methods: {
    cashOutUpdated(newAmount, cashOutIndex) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        cashOuts: this.groupOrPool.cashOuts.map(oldCashOut => {
          if (oldCashOut.weekday === cashOutIndex) {
            return ({
              ...oldCashOut,
              amount: newAmount,
            });
          }

          return oldCashOut;
        }),
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    cashOutForDay(weekday) {
      return this.groupOrPool.cashOuts.find(x => x.weekday === weekday.id)?.amount || null;
    },
    commissionUpdated(newValue) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        commission: newValue,
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
  },
};

</script>

<style
  lang="scss"
  scoped>

</style>
