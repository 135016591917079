<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      vid="bettingPoolSchedules.openingTime">
      <v-text-field
        :error-messages="errors"
        hidden />
    </validation-provider>
    <v-row
      v-for="weekday in weekdays"
      class="mt-3"
      :key="weekday.id">
      <!-- Start time -->
      <v-col
        sm="12"
        md="6">
        <v-menu
          ref="menu"
          v-model="menus[weekday.id].openingTime"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value="scheduleForDay(weekday).openingTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="scheduleForDayDisplayValue(weekday).openingTime"
              :label="$t('enumerations.weekday.' + weekday.name.toLowerCase()) + ' (' +$t('views.groups.create_or_update.start') +')'"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on" />
          </template>
          <v-time-picker
            v-if="menus[weekday.id].openingTime"
            :value="scheduleForDay(weekday).openingTime"
            format="ampm"
            :max="scheduleForDay(weekday).closingTime"
            ampm-in-title
            full-width
            @input="scheduleUpdatedOpeningTime($event, weekday)" />
        </v-menu>
      </v-col>
      <!-- End time -->
      <v-col
        sm="12"
        md="6">
        <v-menu
          ref="menu"
          v-model="menus[weekday.id].closingTime"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value="scheduleForDay(weekday).closingTime"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="scheduleForDayDisplayValue(weekday).closingTime"
              :label="$t('enumerations.weekday.' + weekday.name.toLowerCase()) + ' (' +$t('views.groups.create_or_update.finish') +')'"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on" />
          </template>
          <v-time-picker
            v-if="menus[weekday.id].closingTime"
            :value="scheduleForDay(weekday).closingTime"
            :min="scheduleForDay(weekday).openingTime"
            format="ampm"
            ampm-in-title
            full-width
            @input="scheduleUpdatedClosingTime($event, weekday)" />
        </v-menu>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import Weekday from "../../imported/Weekday.json";
import { mapJsonEnumToObjects } from "@/services/mappers/jsonEnumToObjectMapper.js";

function data() {
  return {
    selectedTab: null,
    menus: [
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
      { openingTime: false, closingTime: false },
    ],
    weekdays: [],
  };
}

export default {
  name: "GroupOrPoolScheduleForm",
  props: {
    groupOrPool: {
      type: Object,
      required: true,
      description: "The group or pool object that has the fields this form possesses",
    },
    validations: {
      type: Object,
      required: true,
      description: "An object that defined the validations to be used on the fields in this component",
    },
  },
  data,
  created() {
    this.weekdays = mapJsonEnumToObjects(Weekday);
  },
  methods: {
    scheduleUpdatedOpeningTime(value, weekday) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        schedules: this.groupOrPool.schedules.map(oldSchedule => {
          if (oldSchedule.weekday === weekday.id) {
            return ({
              ...oldSchedule,
              openingTime: value,
            });
          }

          return oldSchedule;
        }),
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    scheduleUpdatedClosingTime(value, weekday) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        schedules: this.groupOrPool.schedules.map(oldSchedule => {
          if (oldSchedule.weekday === weekday.id) {
            return ({
              ...oldSchedule,
              closingTime: value,
            });
          }

          return oldSchedule;
        }),
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    scheduleForDay(weekday) {
      return this.groupOrPool.schedules.find(x => x.weekday === weekday.id);
    },
    scheduleForDayDisplayValue(weekday) {
      const openingPickerValue = this.scheduleForDay(weekday).openingTime,
        closingPickerValue = this.scheduleForDay(weekday).closingTime;

      return {
        openingTime: this.parseToMeridian(openingPickerValue),
        closingTime: this.parseToMeridian(closingPickerValue),
      };
    },
    parseToMeridian(timeString) {
      if (timeString !== null) {
        const [hours, minutes] = timeString.split(":"),
          period = parseInt(hours, 10) > 12
            ? "PM"
            : "AM";
        const formattedHours = parseInt(hours, 10) >= 12
          ? (parseInt(hours, 10) - 12)
          : parseInt(hours, 10);
        return `${formattedHours === 0
          ? 12
          : formattedHours}:${minutes} ${period}`;
      }

      return null;
    },
  },
};

</script>

<style
  lang="scss"
  scoped>

</style>
