<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.firstFooter"
      slim
      name="first_footer">
      <v-text-field
        :value="groupOrPool.firstFooter"
        :label="$t('attributes.groups.first_footer')"
        :error-messages="errors"
        type="text"
        @input="updatedFirstFooterTo($event)" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.secondFooter"
      slim
      name="second_footer">
      <v-text-field
        :value="groupOrPool.secondFooter"
        :label="$t('attributes.groups.second_footer')"
        :error-messages="errors"
        type="text"
        @input="updatedSecondFooterTo($event)" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.thirdFooter"
      name="third_footer"
      slim>
      <v-text-field
        :value="groupOrPool.thirdFooter"
        :label="$t('attributes.groups.third_footer')"
        :error-messages="errors"
        type="text"
        @input="updatedThirdFooterTo($event)" />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="validations.fourthFooter"
      name="fourth_footer"
      slim>
      <v-text-field
        :value="groupOrPool.fourthFooter"
        :label="$t('attributes.groups.fourth_footer')"
        :error-messages="errors"
        type="text"
        @input="updatedFourthFooterTo($event)" />
    </validation-provider>
  </v-form>
</template>

<script>

export default {
  name: "GroupOrPoolFooterForm",
  props: {
    groupOrPool: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updatedFirstFooterTo(firstFooter) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        firstFooter,
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    updatedSecondFooterTo(secondFooter) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        secondFooter,
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    updatedThirdFooterTo(thirdFooter) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        thirdFooter,
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
    updatedFourthFooterTo(fourthFooter) {
      const updatedGroupOrPool = {
        ...this.groupOrPool,
        fourthFooter,
      };

      this.$emit("update:group-or-pool", updatedGroupOrPool);
    },
  },
};
</script>
