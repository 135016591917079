function appendFormData(formData, data, root) {
  root = root || "";
  if (data instanceof File) {
    formData.append(root, data);
  } else if (Array.isArray(data)) {
    const objectsArray = data.some(x => typeof x === "object");
    if (objectsArray) {
      for (let i = 0; i < data.length; i++) {
        appendFormData(formData, data[i], root + "[" + i + "]");
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        formData.append(root + "[]", data[i]);
      }
    }
  } else if (typeof data === "object" && data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (root === "") {
          appendFormData(formData, data[key], key);
        } else {
          appendFormData(formData, data[key], root + "[" + key + "]");
        }
      }
    }
  } else {
    if (data !== null && typeof data !== "undefined") {
      formData.append(root, data);
    }
  }
}


export function mapObjectToFormData(obj, rootName) {
  const formData = new FormData();

  appendFormData(formData, obj, rootName);

  return formData;
}
