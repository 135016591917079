<template>
  <v-form>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.username"
      name="username">
      <v-text-field
        :value="user.username"
        :label="$t('attributes.users.username')"
        name="username"
        :error-messages="errors"
        type="text"
        @input="usernameUpdatedTo($event)" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.password"
      name="password">
      <v-text-field
        :value="user.password"
        :label="$t('attributes.users.password')"
        :error-messages="errors"
        type="password"
        @input="passwordUpdatedTo($event)" />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      :rules="validations.passwordConfirmation"
      name="confirmation">
      <v-text-field
        :value="user.passwordConfirmation"
        :label="$t('attributes.users.passwordConfirmation')"
        type="password"
        :error-messages="errors"
        @input="passwordConfirmationUpdatedTo($event)" />
    </validation-provider>
    <validation-provider
      v-if="validations.zones"
      v-slot="{ errors }"
      :rules="validations.zones"
      name="zones"
      slim>
      <v-autocomplete
        :value="user.zoneIds"
        :items="zones"
        :error-messages="errors"
        item-text="label"
        item-value="value"
        :label="$t('attributes.users.zones')"
        multiple
        @input="zonesChanged" />
    </validation-provider>
  </v-form>
</template>

<script>
	import { ValidationProvider } from "vee-validate";

	export default {
		name: "UserForm",
		components: {
			ValidationProvider,
		},
		props: {
			user: {
				type: Object,
				required: true,
			},
			validations: {
				type: Object,
				required: true,
			},
			zones: {
				type: Array,
				required: false,
				default: () => []
			},
		},
		methods: {
			usernameUpdatedTo(username) {
				const updatedUser = {
					...this.user,
					username,
				};

				this.$emit("update:user", updatedUser);
			},
			passwordUpdatedTo(password){
				const updatedUser = {
					...this.user,
					password,
				};

				this.$emit("update:user", updatedUser);
			},
			passwordConfirmationUpdatedTo(passwordConfirmation){
				const updatedUser = {
					...this.user,
					passwordConfirmation,
				};

				this.$emit("update:user", updatedUser);
			},
			zonesChanged(value) {
				const updatedUser = {
					...this.user,
					zoneIds: value,
				};

				this.$emit("update:user", updatedUser);
			},
		},
	};
</script>
