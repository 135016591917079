import httpClient from "./httpClient";
import GroupUpdateCategory from "@/imported/GroupUpdateCategory.json";
import GroupShowCategory from "@/imported/GroupShowCategory.json";
import { mapObjectToFormData } from "@/api/helpers.js";

const resource = "groups";

export function createGroup(group) {
  const mappedForm = mapObjectToFormData({
    group: group
  });

  return httpClient.post(`/${resource}`, mappedForm, {
    headers: { "Content-Type": "multipart/form-date" },
  });
}

export function fetchGroups() {
  return httpClient.get(`/${resource}`);
}

export function fetchGroup(id) {
  return httpClient.get(`/${resource}/show`, {
    params: {
      id,
      category: GroupShowCategory.general,
    },
  });
}

export function fetchMyGroup() {
  return httpClient.get(`/${resource}/show`, {
    params: { category: GroupShowCategory.myGroup },
  });
}

export function updateGroup(id, form) {
  const mappedForm = mapObjectToFormData({
    group: form,
    category: GroupUpdateCategory.general,
  });

  return httpClient.put(`/${resource}/${id}`, mappedForm, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateGroupActiveStatus(id, active) {
  const params = {
    group: {
      id,
      active,
    },
    category: GroupUpdateCategory.activeStatus,
  };

  return httpClient.put(`/${resource}/${id}`, params);
}

export function updateMyGroup(id, form) {
  const submitForm = {
    group: {
      ...form,
      id,
    },
    category: GroupUpdateCategory.myGroup,
  };

  return httpClient.put(`/${resource}/${id}`, submitForm);
}

export function getParams(groupId) {
  return httpClient.get(`/${resource}/params`, { params: { groupId } });
}
