export function mapJsonEnumToObjects(jsonEnum){
  return Object.keys(jsonEnum).
    map(jsonKey => {
        return ({ id: jsonEnum[jsonKey], name: jsonKey });
      },
    )
}

export default {
  mapJsonEnumToObjects
}
