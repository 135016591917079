var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('validation-provider',{attrs:{"rules":_vm.validations.firstFooter,"slim":"","name":"first_footer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.groupOrPool.firstFooter,"label":_vm.$t('attributes.groups.first_footer'),"error-messages":errors,"type":"text"},on:{"input":function($event){return _vm.updatedFirstFooterTo($event)}}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.secondFooter,"slim":"","name":"second_footer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.groupOrPool.secondFooter,"label":_vm.$t('attributes.groups.second_footer'),"error-messages":errors,"type":"text"},on:{"input":function($event){return _vm.updatedSecondFooterTo($event)}}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.thirdFooter,"name":"third_footer","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.groupOrPool.thirdFooter,"label":_vm.$t('attributes.groups.third_footer'),"error-messages":errors,"type":"text"},on:{"input":function($event){return _vm.updatedThirdFooterTo($event)}}})]}}])}),_c('validation-provider',{attrs:{"rules":_vm.validations.fourthFooter,"name":"fourth_footer","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.groupOrPool.fourthFooter,"label":_vm.$t('attributes.groups.fourth_footer'),"error-messages":errors,"type":"text"},on:{"input":function($event){return _vm.updatedFourthFooterTo($event)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }